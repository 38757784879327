import {get} from 'lodash';
import {authorization} from '@perion-undertone/ut-permissions-module';

import {loadFromLocalStorage} from '../utils';
import {getPublisherId} from '../helpers/historyHelper';

export const isAuthorized = ({permission}) => {
    const user = loadFromLocalStorage('user');
    const username = get(user, 'email');

    const publisherId = getPublisherId();
    const id = publisherId.toString();

    const context = {type: 'publisher', id};

    return authorization.resolve(permission, username, context);
};
