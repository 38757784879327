import React from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import {openAdsTxtModal} from '../actionImports';
import Icon from '../../../Icon';

const MISSING_ADS_TXT_STATUS_ID = 3;

const statusLinkageMapper = {
    0: {label: 'Pending Approval', className: 'pending'},
    1: {label: 'Approved', className: 'approved'},
    2: {label: 'Not Approved', className: 'not-approved'},
    3: {label: 'Missing ads.txt', className: 'missing-ads-txt'}
};

const CLASS_NAMES = {
    STATUS_PREFIX: 'supply-status-',
    WARNING_EXCLAMATION: 'warning-exclamation'
}

const SupplyStatusFormatter  = ({row}) => {
    const dispatch = useDispatch();

    const setOpenAdsTxtModal = () => {
        dispatch(openAdsTxtModal(row));
    }

    const {status} = row;
    const label = statusLinkageMapper[status].label;
    const className = statusLinkageMapper[status].className;

    return (
        <div className={className}>
            {status === MISSING_ADS_TXT_STATUS_ID &&
                <span className={CLASS_NAMES.WARNING_EXCLAMATION} onClick={setOpenAdsTxtModal}>
                    <Icon icon="warning_exclamation.svg" />
                </span>
            }
            <span className={`${CLASS_NAMES.STATUS_PREFIX}${className}`}>
                {label}
            </span>
        </div>);
}

SupplyStatusFormatter.propTypes = {
    row: PropTypes.object,
};


export default SupplyStatusFormatter;