import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {Checkbox} from '@perion-undertone/ut-react-common';
import {setSelectedPlacementGroupsIds} from '../imports';

const CLASS_NAMES = {
    REACT_GRID_CHECKBOX_CONTAINER: 'react-grid-checkbox-container'
};

const PlacementGroupCheckbox = ({row}) => {
    const dispatch = useDispatch();
    const selectedGroupsIds = useSelector(state => state.placements.selectedGroupsIds);

    const setSelectedRows = placementGroupId => {
        dispatch(setSelectedPlacementGroupsIds(placementGroupId));
    }

    const isChecked = selectedGroupsIds && selectedGroupsIds.includes(row.placementGroupId);
    return (
        <div className={CLASS_NAMES.REACT_GRID_CHECKBOX_CONTAINER}>
            <Checkbox squared
                  className={CLASS_NAMES.SELECT_BOX}
                  checked={isChecked}
                  onChange={() => setSelectedRows(row.placementGroupId)}
                  disabled={row.isAuto}
                  hookId='select-placement-group-checkbox'
            />
        </div>);
}

PlacementGroupCheckbox.propTypes = {
    row: PropTypes.object
};

export default PlacementGroupCheckbox;
