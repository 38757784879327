import React from 'react';
import PropTypes from 'prop-types';
import {Icon} from '../imports';
import {Tooltip} from '@perion-undertone/ut-react-common';
import {textProvider} from '../imports';
import {get} from 'lodash';

const CONSTANT_TEXTS = {
    PUBLISHER_LEVEL_BLOCKED_ADVERTISER: textProvider.getText('supplySourceSettingsFormatter', 'publisherLevelBlockedAdvertiser'),
    PUBLISHER_LEVEL_BLOCKED_ADVERTISER_DOMAIN: textProvider.getText('supplySourceSettingsFormatter', 'publisherLevelBlockedAdvertiserUrl')
}

const BlockedAdvertiserNameFormatter = (props) => {
    const isAvertiserUrl = props.dependentValues.columnInfo.isAvertiserUrl;
    const isPublisherLevel = props.dependentValues.columnInfo.isPublisherLevel;
    const toolTiptext = isAvertiserUrl ? CONSTANT_TEXTS.PUBLISHER_LEVEL_BLOCKED_ADVERTISER_DOMAIN : CONSTANT_TEXTS.PUBLISHER_LEVEL_BLOCKED_ADVERTISER; 
    const blockedAdvertiserName = get(props.row, isAvertiserUrl ? 'advertiserUrl' : 'Advertiser.name', '');
    const supplySourceNameView = blockedAdvertiserName;
    const trigger = (<div>
        <Icon icon='pub_level.svg' />
        <span className='publisher-level-blocked-sales-account'>
            {blockedAdvertiserName}
        </span>
    </div>);
    const publisherNameView = (
        <Tooltip trigger={trigger}
                 placement='bottom'
                 appendToBody={true}
                 hookId="blocklist-name-formatter-tooltip"
        >
            {toolTiptext}
        </Tooltip>
    );
    return (<div>
        {props.row.publishersSupplySourcesId === 0 && !isPublisherLevel ?
            publisherNameView :
            supplySourceNameView}
    </div>);
};

BlockedAdvertiserNameFormatter.propTypes = {
    row: PropTypes.object,
    dependentValues: PropTypes.object
};

export default BlockedAdvertiserNameFormatter;