import * as fn from './functions';

// Not localization aware
export const toMoney = (value, withDecimal = false, showSignOnRight = false) => {
    value = withDecimal ? value.toFixed(2) : Math.round(value);
    if (showSignOnRight) {
        return `${value.toLocaleString()}$`;
    } else {
        return `$${value.toLocaleString()}`;
    }
}

export const toThousands = (value) => `${Number(value).toLocaleString()}`;

export const toPercent = (value, noDivide = false, withDecimal = true) => {
    value = noDivide ? value : value * 100;
    value = withDecimal ? parseFloat(value).toFixed(2) : Math.round(value);
    value = parseFloat(value); // to remove trailing zeroes
    return `${value}%`;
}

export const kindFormatters = {
    money: value => toMoney(value),
    moneyWihDecimal: value => toMoney(value, true),
    thousands: value => toThousands(value),
    percent: value => toPercent(value, true),
    raw: fn.identity
}

export const valueKind = {
    money: 'money',
    moneyWihDecimal: 'moneyWihDecimal',
    thousands: 'thousands',
    percent: 'percent',
    raw: 'raw'
}
export const thousandsToKFormatter = value => (
    value > 999 ? Math.round(value / 1000) + 'K' : value
)