import React, {Fragment, useCallback} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {get, first, split} from 'lodash';
import {Dropdown, Icon} from '@perion-undertone/ut-react-common';

import UndertoneLogo from './UndertoneLogo';
import {isAdmin, isPublisherUserOrPublisherAdmin} from '../auth';
import {logoutUser, SearchPublisher, SelectAllowedPublisher, textProvider} from './imports';
import {useAuth0} from '@auth0/auth0-react';

const CLASS_NAMES = {
    HEADER_WRAPPER: 'header-wrapper',
    HEADER_BACKGROUND: 'header-background',
    USER_TOOLS_WRAPPER: 'user-tools-wrapper',
    USER_TOOLS: 'user-tools',
    USER_SETTINGS: 'user-settings',
    USER_DISPLAY_NAME: 'user-display-name',
    PUBLISHER_NAME_SECTION: 'publisher-name-section',
    PUBLISHER_NAME: 'publisher-name',
    SELECT_PUBLISHER_CONTAINER: 'select-publisher-container',
    CLEAR: 'clear'
};

const CONSTANT_TEXTS = {
    SIGN_OUT: textProvider.getText('account', 'signOut')
};

const SETTINGS = {
    SIGN_OUT: '0'
};

const hasOneAccount = (user) => {
    return user && user.allowedPublisherId && user.allowedPublisherId.split(',').length === 1;
}

const hasMultipleAccounts = (user) => {
    return user && user.allowedPublisherId && user.allowedPublisherId.split(',').length > 1;
};

const getUserFirstName = (user) => {
    return get(user, 'firstName', first(split(get(user, 'name', ''))));
};

const Header = (props) => {
    const {user, logoutUser, publisher, publishers} = props;
    const publisherData = get(publisher, 'data');
    const publishersData = get(publishers, 'data');

    const {logout} = useAuth0();

    const handleSelect = (event, eventKey) => {
        if (eventKey === SETTINGS.SIGN_OUT) {
            removeUser();
        }
    };

    const removeUser = useCallback(() => {
             logout({
                logoutParams: {}
            });
             logoutUser();
    }, [logoutUser, user, logout]);

    return (
        <div className={CLASS_NAMES.HEADER_WRAPPER}>
            <div className={CLASS_NAMES.HEADER_BACKGROUND}>
                <UndertoneLogo />
                <div className={CLASS_NAMES.USER_TOOLS_WRAPPER}>
                    <div className={CLASS_NAMES.USER_TOOLS}>
                        {publisherData && hasOneAccount(user) &&
                            <div className={CLASS_NAMES.PUBLISHER_NAME_SECTION}>
                                <div className={CLASS_NAMES.PUBLISHER_NAME}>
                                    {publisherData.name}
                                </div>
                            </div>
                        }
                        {!isAdmin(user) && <Fragment>
                            <Icon icon='icon-profile' hookId='header-profile-icon' />
                            <div className={CLASS_NAMES.USER_DISPLAY_NAME}>
                                {`Hi, ${getUserFirstName(user)}`}
                            </div>
                            <div className={CLASS_NAMES.USER_SETTINGS}>
                                <Dropdown closeMenuOnOutsideClick={true}
                                          trigger={<div></div>}
                                          onSelect={handleSelect}
                                          closeMenuOnSelect={true}
                                          hookId="user-settings-dropdown-menu"
                                >
                                    <Dropdown.Item eventKey={0}
                                                   value={CONSTANT_TEXTS.SIGN_OUT}
                                                   hookId="user-sign-out"
                                    >
                                        {CONSTANT_TEXTS.SIGN_OUT}
                                    </Dropdown.Item>
                                </Dropdown>
                            </div>
                        </Fragment>}
                    </div>
                </div>
                {publisherData && isAdmin(user) &&
                    <SearchPublisher defaultSelectedPublisher={{
                                         name: publisherData.name,
                                         publisherId: publisherData.id
                                     }}
                    />
                }
                {publisherData && publishersData && isPublisherUserOrPublisherAdmin(user) && hasMultipleAccounts(user) &&
                    <div className={CLASS_NAMES.SELECT_PUBLISHER_CONTAINER}>
                        <SelectAllowedPublisher hasLoading={false} />
                    </div>
                }
                <div className={CLASS_NAMES.CLEAR}></div>
            </div>
        </div>
    );
}

Header.propTypes = {
    user: PropTypes.object,
    publisher: PropTypes.object,
    publishers: PropTypes.object,
    logoutUser: PropTypes.func
};

const mapStateToProps = ({app}) => ({
    user: app.user.data,
    publisher: app.publisher,
    publishers: app.publishers
});

const mapDispatchToProps = {
    logoutUser
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);