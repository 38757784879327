import {of} from 'rxjs';
import ajax from '../utils/ajaxProxy';
import {mergeMap, map, catchError} from 'rxjs/operators';
import {ofType} from 'redux-observable';
import {advertisersConstants} from '../constants/advertisersConstants';
import {
    getAdvertisersUrlSuccess, getAdvertisersUrlFailed
} from '../actions/advertisersActions';
import {ToastProvider} from '@perion-undertone/ut-react-common';

export const advertisersUrlBlockListEpic = actions => (
    actions.pipe(
        ofType(advertisersConstants.GET_ADVERTISERS_URL_REQUEST),
        mergeMap(action => {
            const {publisherId, supplySourceId} = action.payload;
            return ajax.getJSON(`/api/advertisers/domains?publisherId=${publisherId}&supplySourceId=${supplySourceId}`).pipe(
                map(data => getAdvertisersUrlSuccess(data)),
                catchError(error => {
                    ToastProvider.error('Failed to fetch advertisers urls');
                    return of(getAdvertisersUrlFailed(error));
                }))
            })
        )
);