import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Icon from '../../../Icon';
import {Tooltip} from '@perion-undertone/ut-react-common';
import SupplySettingsModal from '../SupplySettingsModal';
import {textProvider} from '../imports';

const CLASS_NAMES = {
    SETTINGS_ACTIONS_WRAPPER: 'settings-actions-wrapper',
    SETTINGS_ICON_WRAPPER: 'settings-icon-wrapper',
    SETTINGS_ICON: 'settings-icon',
};

const CONSTANT_TEXTS = {
    SETTINGS: textProvider.getText('supplySourceSettingsFormatter', 'settings'),
    PUBLISHER_LEVEL_SETTINGS: textProvider.getText('supplySourceSettingsFormatter', 'publisherLevelSettings')
};

const SupplyActionsFormatter = ({className, row, isPublisherLevel: propsIsPublisherLevel}) => {
    const location = useLocation();

    const publisher = useSelector(state => state.app.publisher);

    const [openSettingsModal, setOpenSettingsModal] = useState(false);
    const [isPublisherLevel, setIsPublisherLevel] = useState(propsIsPublisherLevel);
    const [id, setId] = useState(row.id);
    const [isRedirectedFromSupply, setIsRedirectedFromSupply] = useState(false);

    useEffect(() => {
        let urlSearchParams = new URLSearchParams(location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
        if (params.interstitialSettings && propsIsPublisherLevel) {
            setIsPublisherLevel(true);
            setOpenSettingsModal(true);
            setId(publisher.id);
            setIsRedirectedFromSupply(true);
        }
    }, [location.search, propsIsPublisherLevel, publisher.id]);

    const onOpenSettingsModal = () => {
        setOpenSettingsModal(true);
    };

    const onCloseSettingsModal = () => {
        setOpenSettingsModal(false);
    };

    const {displayName, publishersSupplySourcesId} = row;

    const settingsTrigger = (
        <div className={CLASS_NAMES.SETTINGS_ICON} onClick={() => onOpenSettingsModal(id)}>
            <Icon icon="general" hookId={`supply-actions-${isPublisherLevel && 'publisher-'}settings-icon`} />
        </div>
    );

    return (
        <div className={cx(CLASS_NAMES.SETTINGS_ACTIONS_WRAPPER, className)}>
            <div className={CLASS_NAMES.SETTINGS_ICON_WRAPPER}>
                <Tooltip
                    trigger={settingsTrigger}
                    placement="top"
                    appendToBody={true}
                    hookId={`supply-actions-${isPublisherLevel && 'publisher-'}settings-tooltip`}
                >
                    {isPublisherLevel ? CONSTANT_TEXTS.PUBLISHER_LEVEL_SETTINGS : CONSTANT_TEXTS.SETTINGS}
                </Tooltip>
            </div>
            {openSettingsModal && (
                <SupplySettingsModal
                    openModal={openSettingsModal}
                    closeModal={onCloseSettingsModal}
                    closeOnOutsideClick={false}
                    supplySourceNames={[displayName]}
                    publishersSupplySourcesId={publishersSupplySourcesId}
                    isPublisherLevel={isPublisherLevel}
                    isRedirectedFromSupply={isRedirectedFromSupply}
                />
            )}
        </div>
    );
};

SupplyActionsFormatter.propTypes = {
    row: PropTypes.object,
    id: PropTypes.number,
    displayName: PropTypes.string,
    publishersSupplySourcesId: PropTypes.number,
    className: PropTypes.string,
    isPublisherLevel: PropTypes.bool,
    publisher: PropTypes.object,
    location: PropTypes.object,
};

export default SupplyActionsFormatter;
