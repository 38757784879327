import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {textProvider} from '../imports';
import {blockAdvertisersDomains} from '../actionImports';

const CLASS_NAMES = {
    UNBLOCK_ADVERTISER: 'unblock-advertiser'
}

const CONSTANT_TEXTS = {
    UNBLOCK: textProvider.getText('supplySourceSettingsModal', 'unblock'),
    START_UNBLOCKING_DOMAINS: textProvider.getText('supplySourceSettingsModal', 'unblockDomains'),
    UNBLOCKING_DOMAINS_SUCCESS: textProvider.getText('supplySourceSettingsModal', 'unblockedDomainsSuccess'),
    UNBLOCKING_DOMAINS_FAILED: textProvider.getText('supplySourceSettingsModal', 'unblockedDomainsFailed'),
}
class UnblockAdvertiserDomainFormatter extends Component {


    handleUnblock = () => {
        const {blockAdvertisersDomains, publisher, row, dependentValues} = this.props;
        const isPublisherLevel = dependentValues.columnInfo.isPublisherLevel;
        const isBulkBlock = dependentValues.columnInfo.isBulkBlock;

        if(!isBulkBlock) {
            const advertiserToUnblock = [{
                    publisherId: publisher.id,
                    publishersSupplySourcesId: row.publishersSupplySourcesId,
                    advertiserUrl: row.advertiserUrl,
                    isBlocked: false
                }];
    
    
            blockAdvertisersDomains(publisher.id, advertiserToUnblock, isPublisherLevel, isBulkBlock, {
                start: CONSTANT_TEXTS.START_UNBLOCKING_DOMAINS,
                success: CONSTANT_TEXTS.UNBLOCKING_DOMAINS_SUCCESS,
                failed: CONSTANT_TEXTS.UNBLOCKING_DOMAINS_FAILED
            });
        }

    }

    handleBulkUnblock = () => {
        const {publisher, row, dependentValues, blockAdvertisersDomains} = this.props;
        const publishersSupplySourcesIds = dependentValues.columnInfo.publishersSupplySourcesIds;
        const isPublisherLevel = dependentValues.columnInfo.isPublisherLevel;
        const isBulkBlock = dependentValues.columnInfo.isBulkBlock;
        let bulkAdvertisersToUnblock = [];

        if(isBulkBlock){
            for(let publishersSupplySourcesId of publishersSupplySourcesIds) {
                bulkAdvertisersToUnblock.push({
                    publisherId: publisher.id,
                    publishersSupplySourcesId,
                    advertiserUrl: row.advertiserUrl,
                    isBlocked: false
                });
            }
            
            blockAdvertisersDomains(publisher.id, bulkAdvertisersToUnblock, isPublisherLevel, isBulkBlock, {
                start: CONSTANT_TEXTS.START_UNBLOCKING_DOMAINS,
                success: CONSTANT_TEXTS.UNBLOCKING_DOMAINS_SUCCESS,
                failed: CONSTANT_TEXTS.UNBLOCKING_DOMAINS_FAILED
            });
        }

    }

    render() {
        const {dependentValues} = this.props;
        const isPublisherLevel = dependentValues.columnInfo.isPublisherLevel;
        const isBulkBlock = dependentValues.columnInfo.isBulkBlock;
        const unblockAdvertiser = (
            <div className={CLASS_NAMES.UNBLOCK_ADVERTISER}
                 onClick={isBulkBlock ? this.handleBulkUnblock : this.handleUnblock}
            >
                {CONSTANT_TEXTS.UNBLOCK}
            </div>
        );
        return (<div>
            {isPublisherLevel ?
                unblockAdvertiser:
                (this.props.row.publishersSupplySourcesId !== 0 && unblockAdvertiser)}
        </div>);
    }
}

UnblockAdvertiserDomainFormatter.propTypes = {
    blockAdvertisersDomains: PropTypes.func,
    publisher: PropTypes.object,
    row: PropTypes.object,
    dependentValues: PropTypes.object
};

const mapStateToProps = ({app}) => ({
    publisher: app.publisher.data
});

const mapDispatchToProps = ({
    blockAdvertisersDomains
});

export default connect(mapStateToProps, mapDispatchToProps)(UnblockAdvertiserDomainFormatter);