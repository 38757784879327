import UnblockAdvertiserFormatter from './UnblockAdvertiserFormatter';
import BlockedAdvertiserNameFormatter from './BlockedAdvertiserNameFormatter';
import BlockedAdvertiserIdFormatter from './BlockedAdvertiserIdFormatter';
import UnblockAdvertiserDomainFormatter from './UnblockAdvertiserDomainFormatter';
import React from 'react';

const CLASS_NAMES = {
    BLOCKED_ADVERTISER_NAME: 'blocked-advertiser-name',
    BLOCKED_ADVERTISER_ID: 'blocked-advertiser-id',
}

const getBlockedAdvertisersGridSchema = (isPublisherLevel, isBulkBlock, publishersSupplySourcesIds, isAdvertiserDomains) => {
    if(isAdvertiserDomains){
        return [
            {
                name: 'Domain',
                key: 'advertiserUrl',
                cellClass: CLASS_NAMES.BLOCKED_ADVERTISER_NAME,
                formatter: BlockedAdvertiserNameFormatter,
                isAvertiserUrl: true,
                isPublisherLevel: isPublisherLevel,
                getRowMetaData: (rowData, columnInfo) => {
                    return {rowData, columnInfo};
                }
            },
            {
                name: '',
                key: 'actions',
                width: 100,
                formatter: props => <UnblockAdvertiserDomainFormatter {...props}/>,
                isPublisherLevel: isPublisherLevel,
                isBulkBlock: isBulkBlock,
                publishersSupplySourcesIds: publishersSupplySourcesIds,
                getRowMetaData: (rowData, columnInfo) => {
                    return {rowData, columnInfo};
                }
            }
        ];
    }
    
    
    
    
    return [
        {
            name: 'Advertiser',
            key: 'Advertiser.name',
            cellClass: CLASS_NAMES.BLOCKED_ADVERTISER_NAME,
            formatter: BlockedAdvertiserNameFormatter,
            isPublisherLevel: isPublisherLevel,
            getRowMetaData: (rowData, columnInfo) => {
                return {rowData, columnInfo};
            }
        },
        {
            name: 'ID',
            key: 'advertiserId',
            cellClass: CLASS_NAMES.BLOCKED_ADVERTISER_ID,
            formatter: BlockedAdvertiserIdFormatter,
            isPublisherLevel: isPublisherLevel,
            getRowMetaData: (rowData, columnInfo) => {
                return {rowData, columnInfo};
            }
        },
        {
            name: '',
            key: 'actions',
            width: 100,
            formatter: props => <UnblockAdvertiserFormatter {...props} />,
            isPublisherLevel: isPublisherLevel,
            isBulkBlock: isBulkBlock,
            publishersSupplySourcesIds: publishersSupplySourcesIds,
            getRowMetaData: (rowData, columnInfo) => {
                return {rowData, columnInfo};
            }
        }
    ];
};

export default getBlockedAdvertisersGridSchema;
