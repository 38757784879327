import React from 'react';
import PropTypes from 'prop-types';
import {Provider} from 'react-redux';
import {HashRouter as Router, useLocation} from 'react-router-dom';
import configureStore from '../configureStore';
import {Auth0Provider} from '@auth0/auth0-react';
import _ from 'lodash';
import AppLayout from './AppLayout';

const App = (props) => (
    <Provider store={configureStore(props.initialState)}>
        <Router>
            <AuthWrapper />
        </Router>
    </Provider>
);

const AuthWrapper = () => {
    const clientId = _.get(window, 'clientConfig.auth0ClientId');
    const domainUrl = _.get(window, 'clientConfig.domain');
    const internalConnection = _.get(window, 'clientConfig.internalConnection', 'workflow-db');
    const externalConnection = _.get(window, 'clientConfig.externalConnection', 'cognito');
    const location = useLocation();
    const isInternal = _.startsWith(location.pathname, '/internal');

    return (
        <Auth0Provider
            domain={domainUrl}
            clientId={clientId}
            cacheLocation="localstorage"
            authorizationParams={{
                scope: 'profile email offline-access',
                audience: 'longtail-publisher',
                redirect_uri: `${window.location.href}`,
                connection: isInternal ? internalConnection : externalConnection,
            }}
        >
            <AppLayout />
        </Auth0Provider>
    );
};

App.propTypes = {
    initialState: PropTypes.object,
};

export default App;
