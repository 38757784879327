import React, {Fragment, Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {selectFeatureFlag} from '@flopflip/react-redux';
import cx from 'classnames';
import {Modal, Button, confirm} from '@perion-undertone/ut-react-common';
import BlockList from './blocklist/BlockList';
import _ from 'lodash';
import VideoLimitations from './videoLimitations/VideoLimitations';
import AdunitConfigurations from './adunitConfigurations/AdunitConfigurations';
import InterstitialSettings from './interstitialSettings/InterstitialSettings';
import {textProvider, formatString, Icon, advertiserUidGenerator} from './imports';
import {
    blockCategories,
    getBlockedCategories,
    getCategories,
    getBlockedAdvertisers,
    blockAdvertisers,
    getBulkBlockedCategories,
    bulkBlockCategories,
    blockAdvertisersDomains
} from './actionImports';
import SystemAppend from './SystemAppend';
import {getAdvertisersUrls, getBulkAdvertisersUrls} from '../../../actions/advertisersActions';

const CLASS_NAMES = {
    SUPPLY_SETTINGS_MODAL: 'supply-settings-modal',
    HEADER: 'supply-settings-modal-header',
    HEADER_INNER: 'supply-settings-modal-header-inner',
    BODY: 'supply-settings-modal-body',
    SIDEBAR: 'supply-settings-modal-sidebar',
    SIDEBAR_MENU: 'supply-settings-modal-sidebar-menu',
    SIDEBAR_MENU_ITEM: 'supply-settings-modal-sidebar-menu-item',
    SIDEBAR_MENU_ITEM_ACTIVE: 'supply-settings-modal-sidebar-menu-item--active',
    MAIN_CONTAINER: 'supply-settings-modal-main-container',
    FOOTER: 'supply-settings-modal-footer',
    DONE_BUTTON: 'supply-settings-modal-done-button',
    CANCEL_BUTTON: 'supply-settings-modal-cancel-button',
    BULK_BLOCK_MESSAGE: 'bulk-block-message'
};


const CONSTANT_TEXTS = {
    START_BLOCKING_DOMAINS: textProvider.getText('supplySourceSettingsModal', 'blockingDomains'), 
    BLOCKING_DOMAINS_SUCCESS: textProvider.getText('supplySourceSettingsModal', 'blockedDomainsSuccess'), 
    BLOCKING_DOMAINS_FAILED: textProvider.getText('supplySourceSettingsModal', 'blockedDomainsFailed'), 
    GENERAL: textProvider.getText('supplySourceSettingsModal', 'general'),
    PERFORMANCE: textProvider.getText('supplySourceSettingsModal', 'performance'),
    BLOCK_LIST: textProvider.getText('supplySourceSettingsModal', 'blockList'),
    SYSTEM_APPEND: textProvider.getText('supplySourceSettingsModal', 'systemAppend'),
    VIDEO_LIMITATIONS: textProvider.getText('supplySourceSettingsModal', 'videoLimitationTitle'),
    DONE: textProvider.getText('supplySourceSettingsModal', 'doneButton'),
    MUTUAL_VALUES: textProvider.getText('supplySourceSettingsModal', 'bulkBlockMutualValues'),
    CONFIRM_BULK_ACTION_TITLE: textProvider.getText('supplySourceSettingsModal', 'confirmBulkActionTitle'),
    CONFIRM_BULK_ACTION_BODY: textProvider.getText('supplySourceSettingsModal', 'confirmBulkActionBody'),
    CONFIRM_BULK_ACTION_BUTTON_TEXT: textProvider.getText('supplySourceSettingsModal', 'confirmBulkActionButtonText'),
    ADUNIT_CONFIGURATIONS: textProvider.getText('supplySourceSettingsModal', 'adunitConfigurationsTitle'),
    INTERSTITIAL_SETTINGS: textProvider.getText('supplySourceSettingsModal', 'interstitialSettingsTitle'),
}
const sidebarOptions = [
    {
        component: InterstitialSettings,
        label: 'interstitial-settings',
        displayName: CONSTANT_TEXTS.INTERSTITIAL_SETTINGS,
        icon: 'general',
        disabled: false,
        showOnlyOnPublisherLevel: true
    },
    {
        component: BlockList,
        label: 'block-list',
        displayName: CONSTANT_TEXTS.BLOCK_LIST,
        icon: 'block-list.svg',
        disabled: false
    },
    {
        component: VideoLimitations,
        label: 'video-limitations',
        displayName: CONSTANT_TEXTS.VIDEO_LIMITATIONS,
        icon: 'video-limitation.svg',
        disabled: false
    },
    {
        component: AdunitConfigurations,
        label: 'adunit-configurations',
        displayName: CONSTANT_TEXTS.ADUNIT_CONFIGURATIONS,
        icon: 'adunit-configurations.svg',
        disabled: false
    },
    {
        component: SystemAppend,
        label: 'system-append-pixel',
        displayName: CONSTANT_TEXTS.SYSTEM_APPEND,
        icon: 'system-append.svg',
        disabled: false
    }
]

class SupplySettingsModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            advertiserValues: [],
            currentView: this.props.isRedirectedFromSupply ? InterstitialSettings : BlockList,
            activeItem: this.props.isRedirectedFromSupply ?'interstitial-settings' : 'block-list',
            activeView: this.props.isRedirectedFromSupply ?'interstitial-settings' : 'block-list',
            blockedCategories: [],
            openBulkBlockCategoriesConfirmation: false,
            openBulkBlockAdvertisersConfirmation: false,
            duration: {label: 'None', value: 0},
            skippable: 0
        };
    }

    componentDidMount() {
        const {
            isPublisherLevel, publisher, publishersSupplySourcesId,
            getBlockedCategories, getCategories,
            getBlockedAdvertisers, publishersSupplySourcesIds,
            getBulkBlockedCategories, getAdvertisersUrls, getBulkAdvertisersUrls
        } = this.props;
        const supplySourceId = isPublisherLevel ? 0 : publishersSupplySourcesId;
        getCategories();
        const sIds = publishersSupplySourcesIds ? publishersSupplySourcesIds : [publishersSupplySourcesId];
        if (publishersSupplySourcesIds) {
            getBlockedAdvertisers(publisher.id, sIds, isPublisherLevel);
            getBulkBlockedCategories(publisher.id, publishersSupplySourcesIds)
            getBulkAdvertisersUrls(publisher.id, publishersSupplySourcesIds);
            return;
        }
        getAdvertisersUrls(publisher.id, supplySourceId, isPublisherLevel);
        getBlockedAdvertisers(publisher.id, sIds, isPublisherLevel);
        getBlockedCategories(publisher.id, supplySourceId, isPublisherLevel);
    }

    cancelModal = () => {
        this.props.closeModal();
    }

    confirmOperation = () => {
        const {closeModal, onConfirm} = this.props;
        onConfirm();
        closeModal();
    }

    handleViewChange = (item) => {
        this.setState({currentView: item.component, activeItem: item.label, activeView: item.viewId});
    }
    filterOutDuplicates = (categoriesToBlock) => {
        const {isBulkBlock, blockedCategories, bulkBlockedCategories} = this.props;
        const blockedCat = isBulkBlock ? bulkBlockedCategories : blockedCategories;

        const matches = categoriesToBlock.reduce((acc, curr) => {
            const match = blockedCat && blockedCat.data && blockedCat.data.find(cat => {
                return cat.uid === curr.uid;
            });

            if ((!match && curr.isBlocked) || (match && match.isBlocked !== curr.isBlocked)) {
                const currentMatch = acc.find(cat => cat.uid === curr.uid && cat.isBlocked === curr.isBlocked);
                !currentMatch && acc.push(curr);
                return acc;
            }

            const sc = categoriesToBlock.find(a => a.subCategoryId !== null);
            if (sc && curr.subCategoryId === null) {
                acc.push(curr);
                return acc;
            }

            const matchedSubCategory = acc.find(a => a.advertiserCategoryId === curr.advertiserCategoryId && a.subCategoryId !== null);
            const matchedCategory = acc.find(a => a.advertiserCategoryId === curr.advertiserCategoryId && a.subCategoryId === null);
            if (matchedCategory && !matchedSubCategory) {
                const matchedCategoryIndex = acc.indexOf(matchedCategory);
                matchedCategoryIndex !== -1 && acc.splice(matchedCategoryIndex, 1);
                return acc;
            }

            return acc;
        }, []);

        return matches;
    }

    blockedCategoriesToSave = (categoriesToBlock) => {
        const matches = this.filterOutDuplicates(categoriesToBlock);
        this.setState({blockedCategories: matches})
    }

    onCategoriesSave = () => {
        const {
            publisher, publishersSupplySourcesId, isPublisherLevel, isBulkBlock,
            publishersSupplySourcesIds, bulkBlockCategories, blockCategories
        } = this.props;

        if (isBulkBlock) {

            const bulkCategoriesToBlock = this.state.blockedCategories.reduce((result, value) => {
                const {advertiserCategoryId, subCategoryId, isBlocked} = value;
                publishersSupplySourcesIds.map(publishersSupplySourcesId => {
                    const uid = `${publisher.id}-${publishersSupplySourcesId}-${advertiserCategoryId}-${subCategoryId || '00'}`;
                    return result.push({
                        publisherId: publisher.id,
                        publishersSupplySourcesId,
                        uid,
                        advertiserCategoryId: advertiserCategoryId,
                        subCategoryId: subCategoryId,
                        isBlocked: isBlocked
                    });
                });
                return result;
            }, []);

            bulkBlockCategories(publisher.id, bulkCategoriesToBlock);
            this.blockedCategoriesToSave([]);
            return;
        }
        blockCategories(publisher.id, publishersSupplySourcesId, this.state.blockedCategories, isPublisherLevel);
        this.blockedCategoriesToSave([]);
    }

    onCategoriesCancel = () => {
        this.blockedCategoriesToSave([]);
        const item = {component: BlockList, label: 'block-list', viewId: 'block-list'}
        this.handleViewChange(item);
    }

    handleAdvertisersValueChange = (advertiserValues) => {
        this.setState({advertiserValues});
    }

    handleBlockAdvertisers = () => {
        const {publisher, publishersSupplySourcesId, blockAdvertisers, isPublisherLevel} = this.props;

        const advertisers = this.state.advertiserValues.map(value => {
            const uid = advertiserUidGenerator(isPublisherLevel, publisher.id, publishersSupplySourcesId, value.id);
            return {
                publisherId: publisher.id,
                publishersSupplySourcesId,
                advertiserId: value.id,
                isBlocked: true,
                uid
            };
        });

        blockAdvertisers(publisher.id, advertisers, isPublisherLevel);
        this.setState({advertiserValues: []});
    }

    handleBulkBlockAdvertisers = () => {
        const {publisher, publishersSupplySourcesIds, blockAdvertisers} = this.props;

        const bulkAdvertisers = this.state.advertiserValues.reduce((result, value) => {
            publishersSupplySourcesIds.map(publishersSupplySourcesId => {
                const uid = advertiserUidGenerator(false, publisher.id, publishersSupplySourcesId, value.id);
                return result.push({
                    publisherId: publisher.id,
                    publishersSupplySourcesId,
                    advertiserId: value.id,
                    isBlocked: true,
                    uid
                });
            });
            return result;
        }, []);

        blockAdvertisers(publisher.id, bulkAdvertisers);
        this.setState({advertiserValues: []});
    }
    onConfirmationModalOpen = async (confirmationType) => {
        const {supplySourceNames, publisher, isPublisherLevel, isBulkBlock} = this.props;
        const title = isPublisherLevel ? publisher.name : supplySourceNames.join(', ');
        const modalBodyMessage = formatString(CONSTANT_TEXTS.CONFIRM_BULK_ACTION_BODY, title);
        const categories = {
            headerText: CONSTANT_TEXTS.CONFIRM_BULK_ACTION_TITLE,
            confirmationMessage: modalBodyMessage,
            confirmButtonText: CONSTANT_TEXTS.CONFIRM_BULK_ACTION_BUTTON_TEXT,
            onConfirm:this.onCategoriesSave
        };
        const advertisers = {
            headerText: CONSTANT_TEXTS.CONFIRM_BULK_ACTION_TITLE,
            confirmationMessage: modalBodyMessage,
            confirmButtonText: CONSTANT_TEXTS.CONFIRM_BULK_ACTION_BUTTON_TEXT,
            onConfirm: isBulkBlock ? this.handleBulkBlockAdvertisers : this.handleBlockAdvertisers
        };
        const confirmProps = confirmationType === 'openBulkBlockAdvertisersConfirmation' ? advertisers : categories;
        await confirm(confirmProps);
        this.setState({[confirmationType]: true});
    }

    onConfirmationModalClose = (confirmationType) => {
        this.setState({[confirmationType]: false});
    }
    handleSaveModalChanges = () => {
        const {isPublisherLevel} = this.props;
        if (isPublisherLevel) {
            this.onCategoriesSave();
        } else{
            this.onConfirmationModalOpen('openBulkBlockCategoriesConfirmation');
        }
    }

    manageBlockDomains = (domains) => {
        const {blockAdvertisersDomains, isPublisherLevel, publishersSupplySourcesIds,
            publisher, publishersSupplySourcesId, isBulkBlock} = this.props;

        const supplySourceId = isPublisherLevel ? 0 : publishersSupplySourcesId;
        const sIds = publishersSupplySourcesIds ? publishersSupplySourcesIds : [publishersSupplySourcesId];
        if(_.isEmpty(domains)) {
               return; 
        }
        let bulkDomainsToChange = [];
        const uniqueDomains = _.uniqBy(domains, 'value');
        if (isBulkBlock) {
                for(let tempsupplySrouceId of sIds) {
                        bulkDomainsToChange.push(..._.map(uniqueDomains, domain => {
                            return {
                                publisherId: publisher.id,
                                publishersSupplySourcesId: tempsupplySrouceId,
                                advertiserUrl: _.toLower(domain.value),
                                isBlocked: true,
                            }
                        }));
                }
        }else if(isPublisherLevel){
            
            bulkDomainsToChange = _.map(uniqueDomains, domain => {
                return {
                    publisherId: publisher.id,
                    publishersSupplySourcesId: 0,
                    advertiserUrl: _.toLower(domain.value),
                    isBlocked: true,
                }
            });

        }else{
            bulkDomainsToChange = _.map(uniqueDomains, domain => {
                return {
                    publisherId: publisher.id,
                    publishersSupplySourcesId: supplySourceId,
                    advertiserUrl: _.toLower(domain.value),
                    isBlocked: true,
                }
            });
        }
        const texts = {
            start: CONSTANT_TEXTS.START_BLOCKING_DOMAINS,
            success: CONSTANT_TEXTS.BLOCKING_DOMAINS_SUCCESS,
            failed: CONSTANT_TEXTS.BLOCKING_DOMAINS_FAILED
        }
        
        blockAdvertisersDomains(publisher.id, bulkDomainsToChange, isPublisherLevel, isBulkBlock,texts);
    }

    manageBlockAdvertisers = () => {
        const {isPublisherLevel, isBulkBlock} = this.props;
        if (isPublisherLevel) {
            isBulkBlock ? this.handleBulkBlockAdvertisers() : this.handleBlockAdvertisers();
        } else{
            this.onConfirmationModalOpen('openBulkBlockAdvertisersConfirmation');
        }
    }

    getBulkBlockMessage = () => <span className={CLASS_NAMES.BULK_BLOCK_MESSAGE}>{CONSTANT_TEXTS.MUTUAL_VALUES}</span>;

    getSidebarOption = (item, index) => {
        return (<li key={index}
                    onClick={this.handleViewChange.bind(this, item)}
                    className={cx(CLASS_NAMES.SIDEBAR_MENU_ITEM,
                        {[CLASS_NAMES.SIDEBAR_MENU_ITEM_ACTIVE]: item.label === this.state.activeItem})}
        >
            <Icon icon={item.icon} />
            <span>{item.displayName}</span>
        </li>)
    }

    render() {
        const {
            openModal, closeOnOutsideClick, supplySourceNames, publisher, publishersSupplySourcesId,
            categories, isPublisherLevel, blockedAdvertisers, blockedCategories,
            publishersSupplySourcesIds, isBulkBlock, bulkBlockedCategories, isVideoLimitationsEnabled,
            isPlacementGroupThrottelingOn
        } = this.props;
        
        const {advertiserValues} = this.state;
        const CurrentView = this.state.currentView;
        const title = isPublisherLevel ? publisher.name : supplySourceNames.join(', ');
        const advertisersData = publishersSupplySourcesIds ? blockedAdvertisers : blockedAdvertisers;
        const categoriesData = publishersSupplySourcesIds ? bulkBlockedCategories : blockedCategories;
        const bulkBlockMessage = this.getBulkBlockMessage();

        if (!openModal) {
            return null;
        }

        return (
            <Fragment>
                <Modal open={openModal}
                       className={CLASS_NAMES.SUPPLY_SETTINGS_MODAL}
                       closeOnOutsideClick={closeOnOutsideClick}
                       onModalClose={this.cancelModal}
                       hookId="supply-settings-modal"
                >
                    <div className={CLASS_NAMES.HEADER}>
                        <div className={CLASS_NAMES.HEADER_INNER}>{title}</div>
                    </div>
                    <div className={CLASS_NAMES.BODY}>
                        <div className={CLASS_NAMES.SIDEBAR}>
                            <ul className={CLASS_NAMES.SIDEBAR_MENU}>
                                {sidebarOptions.map((item, index) => {
                                    if((item.label === 'system-append-pixel' && isPublisherLevel) || (!isPlacementGroupThrottelingOn && item.label === 'system-append-pixel')){
                                        return;
                                    }
                                    if (item.showOnlyOnPublisherLevel && !isPublisherLevel) {
                                        return;
                                    }
                                    if (item.label === 'video-limitations') {
                                        return (isVideoLimitationsEnabled && this.getSidebarOption(item, index))
                                    }
                                    return this.getSidebarOption(item, index);
                                })}
                            </ul>
                        </div>
                        <div className={CLASS_NAMES.MAIN_CONTAINER}>
                            {categoriesData && advertisersData &&
                            <CurrentView 
                                         onViewChange={this.handleViewChange}
                                         publisherId={publisher.id}
                                         publishersSupplySourcesId={isBulkBlock ? publishersSupplySourcesIds[0] : publishersSupplySourcesId}
                                         blockedAdvertisers={advertisersData}
                                         blockedCategories={categoriesData}
                                         stateBlockedCategories={this.state.blockedCategories}
                                         blockedCategoriesToSave={this.blockedCategoriesToSave}
                                         categories={categories}
                                         isPublisherLevel={isPublisherLevel}
                                         isBulkBlock={isBulkBlock}
                                         isBulkMode={isBulkBlock}
                                         bulkBlockMessage={bulkBlockMessage}
                                         publishersSupplySourcesIds={publishersSupplySourcesIds}
                                         onOpenBulkBlockAdvertisersConfirmation={this.manageBlockAdvertisers}
                                         manageBlockDomains={this.manageBlockDomains}
                                         handleAdvertisersValueChange={this.handleAdvertisersValueChange}
                                         cancelModal={this.cancelModal}
                                         advertiserValues={advertiserValues}
                            />}
                            {this.state.activeView === 'block-list-categories' &&
                                (<div className={CLASS_NAMES.FOOTER}>
                                    <Button onClick={this.onCategoriesCancel}
                                            className={CLASS_NAMES.CANCEL_BUTTON}
                                            transparent={true}
                                            hookId='categories-cancel-button'
                                    >
                                        Cancel
                                    </Button>
                                    <Button onClick={this.handleSaveModalChanges}
                                            className={CLASS_NAMES.DONE_BUTTON}
                                            hookId='categories-save-button'
                                            disabled={this.state.blockedCategories.length === 0}
                                    >
                                        Save
                                    </Button>
                                </div>)
                            }
                        </div>
                    </div>
                </Modal>
            </Fragment>
        )
    }
}

SupplySettingsModal.propTypes = {
    supplySourceNames: PropTypes.array,
    onConfirm: PropTypes.func,
    openModal: PropTypes.bool,
    closeModal: PropTypes.func,
    closeOnOutsideClick: PropTypes.bool,
    publisher: PropTypes.object,
    publishersSupplySourcesId: PropTypes.number,
    blockCategories: PropTypes.func,
    blockedAdvertisers: PropTypes.object,
    blockedCategories: PropTypes.object,
    categories: PropTypes.object,
    isPublisherLevel: PropTypes.bool,
    getCategories: PropTypes.func,
    getBlockedCategories: PropTypes.func,
    getBlockedAdvertisers: PropTypes.func,
    blockAdvertisers: PropTypes.func,
    publishersSupplySourcesIds: PropTypes.array,
    isBulkBlock: PropTypes.bool,
    getBulkBlockedCategories: PropTypes.func,
    bulkBlockedCategories: PropTypes.object,
    bulkBlockCategories: PropTypes.func,
    isVideoLimitationsEnabled: PropTypes.bool,
    isRedirectedFromSupply: PropTypes.bool,
    isPlacementGroupThrottelingOn:  PropTypes.bool,
    getAdvertisersUrls: PropTypes.func,
    blockAdvertisersDomains: PropTypes.func,
    getBulkAdvertisersUrls: PropTypes.func,
};

const mapStateToProps = (state) => ({
    publisher: state.app.publisher.data,
    categories: state.categories.categoriesList,
    blockedCategories: state.supply.blockedCategories,
    blockedAdvertisers: state.supply.blockedAdvertisers,
    blockAdvertisers: state.supply.blockedAdvertisers,
    bulkBlockedCategories: state.supply.bulkBlockedCategories,
    isVideoLimitationsEnabled: selectFeatureFlag('videoLimitations')(state),
    isPlacementGroupThrottelingOn: selectFeatureFlag('isPlacementGroupThrottelingOn')(state)
});

const mapDispatchToProps = ({
    blockCategories,
    getBlockedCategories,
    getCategories,
    getBlockedAdvertisers,
    blockAdvertisers,
    getBulkBlockedCategories,
    bulkBlockCategories,
    getAdvertisersUrls,
    blockAdvertisersDomains,
    getBulkAdvertisersUrls
})

export default connect(mapStateToProps, mapDispatchToProps)(SupplySettingsModal);
