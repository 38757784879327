import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useSelector, useDispatch} from 'react-redux';
import cx from 'classnames';
import {openPlacementGroupModal, deletePlacementGroups} from '../imports';
import Icon from '../../../Icon';
import ConfirmationModal from '../../../ConfirmationModal';
import {Tooltip} from '@perion-undertone/ut-react-common';
import _ from 'lodash';

const CLASS_NAMES = {
    ACTIONS_ICON_WRAPPER: 'actions-icons-wrapper',
    DELETE_ICON_WRAPPER: 'delete-action-wrapper',
    EDIT_ICON_WRAPPER: 'edit-action-wrapper',
    EDIT_ICON_WRAPPER_DISABLED: 'edit-action-wrapper-disabled',
};

const ActionsFormatter = ({row}) => {
    const [openDeleteConfirmationModal, setOpenDeleteConfirmationModal] = useState(false);
    const [placementGroupId, setPlacementGroupId] = useState([]);

    const dispatch = useDispatch();

    // Selectors using `useSelector`
    const publisher = useSelector((state) => state.app.publisher.data);
    const placementsPagination = useSelector((state) => state.placements.placementsPagination);
    const searchTerm = useSelector((state) => state.placements.searchTerm);
    const customization = useSelector((state) => state.grid.customization);

    const deletePlacementGroupRow = () => {
        const sort = _.get(customization, 'sort') || [];
        dispatch(deletePlacementGroups(publisher.id, placementGroupId, placementsPagination.limit, placementsPagination.page, searchTerm, sort));
    };

    const onOpenDeleteConfirmationModal = (placementGroupId) => {
        setOpenDeleteConfirmationModal(true);
        setPlacementGroupId([placementGroupId]);
    };

    const onCloseDeleteConfirmationModal = () => {
        setOpenDeleteConfirmationModal(false);
    };

    const isAutoGeneratedGroup = row.isAuto;

    const deleteTrigger = (
        <div className={cx(CLASS_NAMES.DELETE_ICON_WRAPPER, {[CLASS_NAMES.DELETE_ICON_WRAPPER_DISABLED]: isAutoGeneratedGroup})}
             onClick={() => onOpenDeleteConfirmationModal(row.placementGroupId)}>
            <Icon icon="trash_03" hookId={`${row.name}-delete-button`} />
        </div>
    );

    const editTrigger = (
        <div className={cx(CLASS_NAMES.EDIT_ICON_WRAPPER, {[CLASS_NAMES.EDIT_ICON_WRAPPER_DISABLED]: isAutoGeneratedGroup})}
             onClick={() => dispatch(openPlacementGroupModal(row))}>
            <Icon icon="menu_edit" hookId={`${row.name}-edit-button`} />
        </div>
    );

    return (
        <div>
            <div className={CLASS_NAMES.ACTIONS_ICON_WRAPPER}>
                <Tooltip trigger={editTrigger}
                         placement='top'
                         appendToBody={true}
                         className={CLASS_NAMES.AD_UNITS_TOOLTIP}
                         hookId="actions-formatter-edit-tooltip">
                    Edit
                </Tooltip>
                <Tooltip trigger={deleteTrigger}
                         placement='top'
                         appendToBody={true}
                         className={CLASS_NAMES.AD_UNITS_TOOLTIP}
                         hookId="actions-formatter-delete-tooltip">
                    Delete
                </Tooltip>
            </div>
            {openDeleteConfirmationModal && (
                <ConfirmationModal openModal={openDeleteConfirmationModal}
                                   closeModal={onCloseDeleteConfirmationModal}
                                   closeOnOutsideClick={false}
                                   onConfirm={deletePlacementGroupRow}
                />
            )}
        </div>
    );
};

ActionsFormatter.propTypes = {
    row: PropTypes.object.isRequired,
};

export default ActionsFormatter;
