import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {isEmpty, get} from 'lodash';
import {Button, Icon, getHookId} from '@perion-undertone/ut-react-common';

import BlockListAdvertisers from './BlockListAdvertisers';
import BlockListCategories from './BlockListCategories';
import {textProvider, isAuthorized, permissionConstants} from '../imports';
import {getCategoryName} from '../../../../utils/helpers';
import BlockListAdvertisersDomains from './BlockListAdvertisersDomains';
import {connect} from 'react-redux';

const {SUPPLY_SOURCES_BLOCK_ADVERTISERS} = permissionConstants;

const CLASS_NAMES = {
    INNER_CONTAINER: 'supply-settings-modal-inner-container',
    ADVERTISERS_LIST_CONTAINER: 'block-list-advertisers-list-container',
    ADVERTISERS_LIST: 'block-list-advertisers-list',
    ADVERTISERS_LIST_ELEMENT: 'block-list-advertisers-list-element',
    ADVERTISERS_LIST_NONE: 'block-list-advertisers-list-none',
    TITLE: 'block-list-title',
    DESCRIPTION: 'block-list-description',
    SECTION_WRAPPER: 'section-wrapper',
    SECTION_WRAPPER_SEPARATOR: 'section-wrapper-separator',
    ADVERTISERS_TITLE: 'block-list-advertisers-title',
    SUPPLY_SETTINGS_MODAL_ADVERTISERS: 'supply-settings-modal-advertisers',
    SUPPLY_SETTINGS_MODAL_CATEGORIES: 'supply-settings-modal-categories',
    FOOTER: 'supply-settings-modal-footer',
    DONE_BUTTON: 'supply-settings-modal-done-button',
};

const CONSTANT_TEXTS = {
    NO_ADVERTISERS: textProvider.getText('supplySourceSettingsModal', 'noAdvertisers'),
    NO_ADVERTISERS_DOMAINS: textProvider.getText('supplySourceSettingsModal', 'noAdvertisersDomains'),
    
    NO_CATEGORIES: textProvider.getText('supplySourceSettingsModal', 'noCategories'),
    BLOCK_LIST_DESCRIPTION: textProvider.getText('supplySourceSettingsModal', 'blockListDescription'),
    BULK_BLOCK_LIST_DESCRIPTION: textProvider.getText('supplySourceSettingsModal', 'bulkBlockListDescription'),
    BLOCK_LIST_DESCRIPTION_CATEGORIES: textProvider.getText('supplySourceSettingsModal', 'blockListDescriptionCategories'),
    ADVERTISERS: textProvider.getText('supplySourceSettingsModal', 'advertisers'),
    ADVERTISERS_DOMAINS: textProvider.getText('supplySourceSettingsModal', 'advertisersDomains'),
    
    CATEGORIES: textProvider.getText('supplySourceSettingsModal', 'categories'),
    EDIT: textProvider.getText('supplySourceSettingsModal', 'edit'),
    BLOCK_LIST: textProvider.getText('supplySourceSettingsModal', 'blockList'),
    DONE: textProvider.getText('supplySourceSettingsModal', 'doneButton')
}

class BlockList extends Component {

    mapBlockedCategoriesByName = () => {
        const {blockedCategories, categories} = this.props;
        const blockedSubCategoriesNames = [];

        if (!categories || !categories.data || !blockedCategories.data) return [];

        categories.data.map(category => {
            const filteredBlockedCategories = blockedCategories.data
                .filter(blockedCategory => blockedCategory.advertiserCategoryId === category.id);
            const blockedSubCategories = filteredBlockedCategories && category.AdvertiserSubCategories.filter(sc =>
                filteredBlockedCategories.find(fbc => {
                    if (fbc.subCategoryId === null) return true;
                    return sc.advertiserSubCategoryId === fbc.subCategoryId;
                })
            );
            blockedSubCategories && blockedSubCategories.map(bsc => {
                const categoryName = getCategoryName(bsc);
                blockedSubCategoriesNames.push(categoryName);
            })
        })

        return blockedSubCategoriesNames;
    }

    mapBlockedAdvertisersByName = () => {
        const {blockedAdvertisers} = this.props;
        return blockedAdvertisers.data && blockedAdvertisers.data.map(adv => adv.Advertiser.name);
    }

    mapBlockedAdvertisersByDomain = () => {
        const {advertiserUrlList} = this.props;
        const advertiserUrlListArray = get(advertiserUrlList, 'data', []);
        return Array.isArray(advertiserUrlListArray) && advertiserUrlListArray.map(adv => adv.advertiserUrl);
    }

    getBlockListContent = (noblockedListText, blockedListNames, type) => {
        if (isEmpty(blockedListNames)) {
            return (<div className={CLASS_NAMES.ADVERTISERS_LIST_NONE}
                         {...getHookId(`block-list-${type}-list-none`)}
            >
                {noblockedListText}
            </div>)
        }

        return (<ul className={CLASS_NAMES.ADVERTISERS_LIST}>
            {blockedListNames.map(blElement => {
                return <li key={blElement} className={CLASS_NAMES.ADVERTISERS_LIST_ELEMENT}>{blElement}</li>
            })}
        </ul>)
    }

    formatBlockedList = (noblockedListText, blockedListNames, viewType, type) => {
        const blockListContent = this.getBlockListContent(noblockedListText, blockedListNames, type);

        return (<div className={CLASS_NAMES.ADVERTISERS_LIST_CONTAINER}
                     {...getHookId(`block-list-${type}-list-container`)}
        >
            {blockListContent}
            <Button transparent
                    onClick={this.props.onViewChange.bind(this, viewType)}
                    hookId={`block-${type}-button`}
            >
                <Icon icon="icon-menu_edit" hookId="edit-block-list-button"/>
            </Button>
        </div>)
    }

    render() {
        const {isBulkBlock, bulkBlockMessage} = this.props;
        const advertisersView = {component: BlockListAdvertisers, label: 'block-list', viewId: 'block-list-advertisers'}
        const advertisersURLView = {component: BlockListAdvertisersDomains, label: 'block-list', viewId: 'block-list-advertisers-domains'}
        const categoriesView = {component: BlockListCategories, label: 'block-list', viewId: 'block-list-categories'}

        return (<Fragment>
            <div className={CLASS_NAMES.INNER_CONTAINER}>
                <div className={CLASS_NAMES.TITLE}>{CONSTANT_TEXTS.BLOCK_LIST}</div>
                {
                    isAuthorized({permission: SUPPLY_SOURCES_BLOCK_ADVERTISERS})
                    ?   <Fragment>
                            <div className={CLASS_NAMES.DESCRIPTION}>
                                {isBulkBlock ?
                                    <span>
                                        {CONSTANT_TEXTS.BULK_BLOCK_LIST_DESCRIPTION}
                                        {bulkBlockMessage}
                                    </span> :
                                    CONSTANT_TEXTS.BLOCK_LIST_DESCRIPTION
                                }
                            </div>
                            <div className={CLASS_NAMES.SECTION_WRAPPER}>
                                <div className={CLASS_NAMES.ADVERTISERS_TITLE}
                                     {...getHookId('block-list-advertisers-title')}
                                >
                                    {CONSTANT_TEXTS.ADVERTISERS}
                                </div>
                                <div className={CLASS_NAMES.SUPPLY_SETTINGS_MODAL_ADVERTISERS}>
                                {this.formatBlockedList(CONSTANT_TEXTS.NO_ADVERTISERS, this.mapBlockedAdvertisersByName(), advertisersView, 'advertisers')}
                                </div>
                            </div>
                            <div className={CLASS_NAMES.SECTION_WRAPPER}>
                                <div className={CLASS_NAMES.ADVERTISERS_TITLE}
                                     {...getHookId('block-list-advertisers-title')}
                                >
                                    {CONSTANT_TEXTS.ADVERTISERS_DOMAINS}
                                </div>
                                <div className={CLASS_NAMES.SUPPLY_SETTINGS_MODAL_ADVERTISERS}>
                                    {this.formatBlockedList(CONSTANT_TEXTS.NO_ADVERTISERS_DOMAINS, this.mapBlockedAdvertisersByDomain(), advertisersURLView, 'advertisersUrls')}
                                </div>
                            </div>
                        </Fragment>
                    :   <div className={CLASS_NAMES.DESCRIPTION}>{CONSTANT_TEXTS.BLOCK_LIST_DESCRIPTION_CATEGORIES}</div>
                }
                <div className={CLASS_NAMES.SECTION_WRAPPER}>
                    <div className={CLASS_NAMES.ADVERTISERS_TITLE}
                         {...getHookId('block-list-categories-title')}
                    >
                        {CONSTANT_TEXTS.CATEGORIES}
                    </div>
                    <div className={CLASS_NAMES.SUPPLY_SETTINGS_MODAL_ADVERTISERS}>
                        {this.formatBlockedList(CONSTANT_TEXTS.NO_CATEGORIES, this.mapBlockedCategoriesByName(), categoriesView, 'categories')}
                    </div>
                </div>
            </div>
            <div className={CLASS_NAMES.FOOTER}>
                <Button onClick={this.props.cancelModal}
                        className={CLASS_NAMES.DONE_BUTTON}
                        hookId='done-button'
                >
                    {CONSTANT_TEXTS.DONE}
                </Button>
            </div>
        </Fragment>)
    }
}

BlockList.propTypes = {
    onViewChange: PropTypes.func,
    blockedAdvertisers: PropTypes.object,
    blockedCategories: PropTypes.object,
    categories: PropTypes.object,
    isBulkBlock: PropTypes.bool,
    bulkBlockMessage: PropTypes.object,
    cancelModal: PropTypes.func,
    advertiserUrlList : PropTypes.array
};

const mapStateToProps = ({advertisers}) => ({
    advertiserUrlList: advertisers.advertiserUrlList
});

export default connect(mapStateToProps,  null)(BlockList);