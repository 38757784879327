import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {Icon, confirm} from '@perion-undertone/ut-react-common';
import cx from 'classnames';
import {useDispatch, useSelector} from 'react-redux';
import {toggleAdSlotsEditMode, savePlacementGroupAdSlots, deletePlacementGroupAdSlot} from '../imports';

const IconButton = ({disabled, hookId, onClick, icon, children, disableMessage, className, tooltipMessage}) => {
    const iconButton = (
        <Icon className={cx('icon-button', className)}
            disabled={disabled}
            onClick={children ? _.noop : onClick}
            icon={icon}
            hookId={hookId}
            disableMessage={disableMessage}
            tooltip={!disableMessage ? tooltipMessage : undefined}
        />
    );
    if (!children) {
        return iconButton;
    }
    return (
        <button className={cx('icon-button-with-text', {['icon-button-with-text--disabled']: disabled})}
                onClick={disabled ? _.noop : onClick}
        >
            <div className="icon-button-icon">{iconButton}</div>
            <div className="icon-button-text">{children}</div>
        </button>
    );
};

IconButton.propTypes = {
    disableMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    hookId: PropTypes.string,
    disabled: PropTypes.bool,
    icon: PropTypes.string,
    onClick: PropTypes.func,
    className: PropTypes.string,
    tooltipMessage: PropTypes.string
};

const AdSlotsActionButtonsFormatter = ({row}) => {
    const dispatch = useDispatch();
    const adSlotToEdit = useSelector((state) => state.placements.currentGroup.adSlotToEdit);
    const newAdSlot = useSelector((state) => state.placements.currentGroup.newAdSlot);
    const adSlots = useSelector((state) => state.placements.currentGroup.adSlots);
    const enterEditMode = () => {
        return dispatch(toggleAdSlotsEditMode(row.id));
    }

    const cancelEditMode = () => {
        return dispatch(toggleAdSlotsEditMode(null));
    }

    const onSave = () => {
        return dispatch(savePlacementGroupAdSlots([{...row, name: row.name.trim()}]));
    }

    const onDelete = async () => {
        const isConfirmed = await confirm(getConfirmMessage());
        if(isConfirmed) {
            return dispatch(deletePlacementGroupAdSlot(row));
        }
    }

    const getConfirmMessage = () => {
        return {
            confirmationMessage: 'Are you sure you want to delete this ad slot?',
            headerText: 'Delete ad slot',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }
    }

    const isNameUniqe = () => {
        const match = _.find(adSlots, adSlot => {
            return adSlot.id !== row.id && adSlot.name.trim() === row.name.trim();
        })
        return match;
    }

    const isSaveDisabled = () => {
        const incorectName = isNameUniqe();
        if(incorectName || row.name === '') {
            return true;
        }
        return false;
    }

    const getDisabledMsg = () => {
        const incorectName = isNameUniqe();
        if(incorectName) {
            return 'AdSlot name must be unique, please choose another';
        }
        if(row.name === '') {
            return 'Please enter AdSlot name';
        }
    }

        const isEditMode = !_.isNil(row.id) && adSlotToEdit === row.id ? true : false;
        const disabled = isSaveDisabled();
        const disabledMsg = getDisabledMsg();
        return (
            <div className='ad-slots-action-btns-container'>
                {isEditMode ?
                    <div className="ad-slots-action-btns-row">
                        <IconButton icon="icon-checkmark_checkbox"
                                    className='adslots-icon-save'
                                    onClick={onSave}
                                    disabled={disabled}
                                    disableMessage={disabledMsg}
                                    hookId={`adslots-save-button-${row.rowIndex}`}
                        />
                        <IconButton icon="icon-close"
                                    className='adslots-icon-cancel'
                                    onClick={cancelEditMode}
                                    hookId={`adslots-close-button-${row.rowIndex}`}
                        />
                        <IconButton icon="icon-trash_03"
                                    className='adslots-icon-delete'
                                    onClick={_.isEmpty(newAdSlot) ? onDelete : cancelEditMode}
                                    hookId={`adslots-delete-button-${row.rowIndex}`}
                        />
                    </div> :
                    <div className="ad-slots-action-btns-row ad-slots-action-btns--hovered">
                        <IconButton icon="icon-menu_edit"
                                    onClick={enterEditMode}
                                    disabled={!_.isNil(adSlotToEdit)}
                                    hookId={`adslots-edit-button-${row.rowIndex}`}
                        />
                        <IconButton icon="icon-trash_03"
                                    className='adslots-icon-delete'
                                    onClick={onDelete}
                                    disabled={!_.isNil(adSlotToEdit)}
                                    hookId={`adslots-delete-button-${row.rowIndex}`}
                        />
                    </div>
                }
            </div>);
}

AdSlotsActionButtonsFormatter.propTypes = {
    row: PropTypes.object
};

export default AdSlotsActionButtonsFormatter