import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import cx from 'classnames';
import {Checkbox} from '@perion-undertone/ut-react-common';
import {setSelectedPlacementGroupsIds} from '../imports';

const CLASS_NAMES = {
    REACT_GRID_CHECKBOX_CONTAINER: 'react-grid-checkbox-container',
    REACT_GRID_CHECKBOX_CONTAINER_VISIBLE: 'react-grid-checkbox-container-visible',
};


const  PlacementGroupHeaderCheckbox = () => {
    const dispatch = useDispatch();
    const selectedGroupsIds = useSelector(state => state.placements.selectedGroupsIds);
    const onDeselectAllPlacementGroups = () => {
        dispatch(setSelectedPlacementGroupsIds(null));
    }

    return (<div className={CLASS_NAMES.REACT_GRID_CHECKBOX_CONTAINER}>
        <Checkbox squared
                  className={cx({[CLASS_NAMES.REACT_GRID_CHECKBOX_CONTAINER_VISIBLE]: selectedGroupsIds.length > 0})}
                  checked={selectedGroupsIds.length > 0}
                  onChange={onDeselectAllPlacementGroups}
                  hookId='select-all-placement-groups-checkbox'
            />
    </div>);
}

export default PlacementGroupHeaderCheckbox;
