import React, {Fragment, useCallback, useEffect} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import {featureFlags, getFeaturesSuccess, logoutUser} from './imports';
import {enums} from '@perion-undertone/ramp-helpers';
import {FeatureToggleAdapter, RedirectLayout} from '@perion-undertone/ut-react-common';
import {ConfigureFlopFlip, selectFeatureFlag} from '@flopflip/react-redux';
import AppRouter from './AppRouter';
import {useAuth0} from '@auth0/auth0-react';
import {getUserDetails} from '../actions/userActions';
import metadataProvider from '../utils/MetadataProvider';
import {isAdmin, setUserPublisherId} from '../auth';
import {preRender} from "../actions/preRenderAction";
import AppLogo from './AppLogo';
import {getMenuLinksPermissions} from '@perion-undertone/ramp-helpers';
const AppLayout = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const {auth} = useSelector(state => ({
        auth: state.app.user,
        metaDataIsReady: metadataProvider.isReady(),
        isReady: _.get(state['@flopflip'], 'status.isReady'),
        flags: state['@flopflip'].flags
    }));
    const permissionsForMenuList = useSelector(state => getMenuLinksPermissions(selectFeatureFlag, state));
    const getFeatureConfig = async () => {
        const response = await fetch('/api/features');
        const data = await response.json();
        return data;
    };
    const shouldIncludeRedirectLayout = () => {
        const route = location.pathname; // Access pathname from location
        const regex = new RegExp(/([1-9])\w+/g);
        return (
            route === '/' ||
            regex.test(route)
        );
    };
    const {isAuthenticated, isLoading, user, loginWithRedirect, getAccessTokenSilently, logout} = useAuth0();
    const removeUser = useCallback(() => {
        logout({
            logoutParams: {}
        });
        logoutUser();
    }, [logoutUser, user, logout]);
    useEffect(() => {
        (async () => {
            if (!isAuthenticated && !isLoading && !user) {
                await loginWithRedirect({
                    appState: {
                        returnTo: `${window.location.origin}/#${location.pathname}`
                    }
                });
            } else if (isAuthenticated && !isLoading && user) {
                const accessToken = await getAccessTokenSilently();
                document.cookie = `x-access-token=${accessToken}`;
                // setTimeout(async () => {
                //     document.cookie = 'x-access-token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
                //     await loginWithRedirect();
                // }, 300000);
                const logedUser = _.assign({}, setUserPublisherId(user), {auth0User: _.get(user, 'user_metadata.wf_id')});

                dispatch(getUserDetails(accessToken));
                await preRender({user: logedUser});
                const featureConfig = await getFeatureConfig();
                dispatch(getFeaturesSuccess(featureConfig));
            }
        })()
    }, [isAuthenticated, isLoading, user, loginWithRedirect, getAccessTokenSilently, dispatch]);
    const accessToken = auth.accessToken;
    const {id, email, name} = _.get(auth, 'data') || {};
    const appLayoutCom = (isAdmin(user) && shouldIncludeRedirectLayout() ? <RedirectLayout userId={id}
                                                                                                   email={email}
                                                                                                   userName={name}
                                                                                                   logoComponent={AppLogo}
                                                                                                   onLogout={removeUser}
                                                                                                   permissions={permissionsForMenuList}
        >
            <AppRouter/>
        </RedirectLayout> :
        <AppRouter/>);
    return (isAuthenticated && !isLoading && auth.data ?
        <ConfigureFlopFlip adapter={FeatureToggleAdapter}
                           adapterArgs={{
                               token: accessToken,
                               user: user && user.email, flags: featureFlags.concat(enums.FLAGS_FOR_MENU_LINKS)
                           }}
        >
        <Fragment>
            {appLayoutCom}
        </Fragment>
        </ConfigureFlopFlip> : <div></div>);
}

AppLayout.propTypes = {
    auth: PropTypes.object,
    isReady: PropTypes.bool,
    flags: PropTypes.object,
    metaDataIsReady: PropTypes.bool,
};
AppLayout.defaultProps = {
    auth: {}
};
export default AppLayout;