import {combineEpics} from 'redux-observable';
import {featuresEpic} from './featuresEpic';
import {sendEmailEpic} from './emailEpic';
import {currentPublisherEpic, fetchPublishersEpic} from './appEpic';
import {createAccountEpic} from './accountEpic';
import {requestDataEpic, requestWidgetListEpic, requestWidgetLibraryEpic, updateWidgetPreferencesEpic} from "./dashboardEpic";
import {getOnboardingDataEpic, addMainSiteEpic, validateMainSiteEpic} from './onboardingEpic';
import {loginEpic, getUserInfoEpic, authorizeEpic, resetPasswordEpic, setPasswordEpic} from './authEpic';
import {
    requestPlacementGroupsEpic, requestAdUnitsEpic,
    createPlacementGroupEpic, updatePlacementGroupEpic,
    deletePlacementGroupsEpic, setPlacementGroupStatusEpic, importFileEpic,
    uploadFileEpic, downloadEpic, deleteFileEpic, requestPlacementFileEpic
} from './placementsEpic';
import {
    requestSupplySourcesEpic,
    submitSitesEpic,
    validateDomainEpic,
    requestBlockedAdvertisersEpic,
    blockAdvertisersEpic,
    requestBlockedCategoriesEpic,
    blockCategoriesEpic, setAdsTxtForDomainEpic,
    requestBulkBlockedCategoriesEpic,
    blockDomainsEpic,
    bulkBlockCategoriesEpic,
    requestVideoLimitationsEpic,
    manageVideoLimitationsEpic} from './supplySourcesEpic';
import {
    requestPredefinedReportsListEpic,
    requestPredefinedReportDataEpic,
    requestRawReportDataEpic,
    setReportSchedulingEpic,
    requestPublisherReportsConfigEpic,
    updateReportQueryEpic
} from './analyticsEpic';
import {requestAdvertisersEpic} from "./advertisersEpic";
import {requestCategoriesEpic} from "./categoriesEpic";
import {requestPaymentEpic} from "./paymentEpic";
import {adUnitConfigurationsEpic, saveAdUnitConfigurationsEpic} from './adUnitConfigurationsEpic';
import {updatePublisherEpic} from './updatePublisherEpic';
import {advertisersUrlBlockListEpic} from './advertisersUrlBlockListEpic';
import {advertisersBulkUrlBlockListEpic} from './advertisersBulkUrlBlockListEpic';

export default combineEpics(
    advertisersBulkUrlBlockListEpic,
    blockDomainsEpic,
    advertisersUrlBlockListEpic,
    createAccountEpic,
    loginEpic,
    getUserInfoEpic,
    getOnboardingDataEpic,
    addMainSiteEpic,
    validateMainSiteEpic,
    authorizeEpic,
    currentPublisherEpic,
    fetchPublishersEpic,
    featuresEpic,
    sendEmailEpic,
    requestDataEpic,
    requestWidgetListEpic,
    requestPlacementGroupsEpic,
    requestAdUnitsEpic,
    createPlacementGroupEpic,
    updatePlacementGroupEpic,
    deletePlacementGroupsEpic,
    setPlacementGroupStatusEpic,
    importFileEpic,
    uploadFileEpic,
    downloadEpic,
    deleteFileEpic,
    requestPlacementFileEpic,
    requestSupplySourcesEpic,
    submitSitesEpic,
    requestAdvertisersEpic,
    requestBlockedAdvertisersEpic,
    blockAdvertisersEpic,
    requestCategoriesEpic,
    requestBlockedCategoriesEpic,
    blockCategoriesEpic,
    validateDomainEpic,
    setAdsTxtForDomainEpic,
    resetPasswordEpic,
    setPasswordEpic,
    requestPredefinedReportsListEpic,
    requestPredefinedReportDataEpic,
    requestRawReportDataEpic,
    setReportSchedulingEpic,
    requestBulkBlockedCategoriesEpic,
    bulkBlockCategoriesEpic,
    requestPaymentEpic,
    requestVideoLimitationsEpic,
    manageVideoLimitationsEpic,
    requestWidgetLibraryEpic,
    updateWidgetPreferencesEpic,
    adUnitConfigurationsEpic,
    saveAdUnitConfigurationsEpic,
    requestPublisherReportsConfigEpic,
    updatePublisherEpic,
    updateReportQueryEpic
);