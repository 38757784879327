import {Button, Select, ToastProvider} from '@perion-undertone/ut-react-common';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import isValidDomain from 'is-valid-domain';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {textProvider} from '../imports';

const CONSTANT_TEXTS = {
    FILE_READING_FAILED: textProvider.getText('supplySourceSettingsModal', 'readingFilefaild'), 
    FILE_READING_ABORTED: textProvider.getText('supplySourceSettingsModal', 'readingFileAborted'), 
    Browse: textProvider.getText('supplySourceSettingsModal', 'browse'),
    dragFile: textProvider.getText('supplySourceSettingsModal', 'dragFile'),
    invalidFiles: textProvider.getText('supplySourceSettingsModal', 'invalidFiles'),
    singleInvalidFile: textProvider.getText('supplySourceSettingsModal', 'singleInvalidFile'),
}


export default function DomainsDropZone(props) {
     const [domains, setDomains] = useState([]); 
     const [invalidDomains, setInvalidDomains] = useState([]);
     const domainsRefreshRequests = useMemo(() => props.domainsRefresh, []);


    const onDrop = useCallback((acceptedFiles) => {
        acceptedFiles.forEach((file) => {
          const reader = new FileReader()
          
          reader.onabort = () => ToastProvider.error(CONSTANT_TEXTS.FILE_READING_ABORTED)
          reader.onerror = () => ToastProvider.error(CONSTANT_TEXTS.FILE_READING_FAILED)
          reader.onload = () => {
            const result = reader.result.split(/\r\n|\n|,/g);
            const mappedResults = [];
            const domainsSet = new Set();
            for(let d of result) {
                if(!_.isEmpty(d) && !domainsSet.has(d)) {
                    domainsSet.add(d);
                    mappedResults.push({label: d, value: d, __isNew__: true});
                }
            }
            handleDomainsChange(mappedResults);
          }
          reader.readAsText(file);
        })
        
      }, [])
  
  
    const {getRootProps, getInputProps} = useDropzone({onDrop});
  

    useEffect(() => {
        if(domainsRefreshRequests != props.domainsRefresh) {
            setDomains([]);
            setInvalidDomains([]); 
        }
    }, [props.domainsRefresh]);

   const handleDomainsChange = useCallback(domains => {
    const tempInValidDomains = [];
    const tempValidDomains = [];
    
    if(Array.isArray(domains)){
        for(let domain of domains) {

            if(!isValidDomain(domain.value)) {
                tempInValidDomains.push(domain);
            }else{
                domain.value = _.toLower(domain.value);
                tempValidDomains.push(domain);
            }
    }
    setInvalidDomains(tempInValidDomains);
    setDomains(tempValidDomains);
 }else{
    setDomains(domains);
 }

}, []);

    if(domains && domains.length > 0) {
     return   <>
            <div className='selected-domains-dropdown'>
            <Select creatable
                    isMulti                            
                    name="domains"
                    hookId="domains-select-dropzone"
                    value={domains}
                    openMenuOnFocus={false}
                    openMenuOnClick={false}
                    styles={props.getCustomStyles}
                    onChange={handleDomainsChange}
                    formatCreateLabel={props.formatCreateLabel}
                    type="domain"
            />
               <Button
                disabled={_.get(domains, 'length',  0) < 1}
                hookId='handle-block-domains-btn'
                onClick={() => props.setBlockDomains(domains)}
            >
            {props.blockBtnText}
        </Button>
            </div>
           
            {invalidDomains.length >= 1 && <div className='invalid-domain-error'>
                *{_.get(_.first(invalidDomains), 'value')} { invalidDomains.length > 1 ? `+${invalidDomains.length - 1} ${CONSTANT_TEXTS.invalidFiles}` : ` ${CONSTANT_TEXTS.singleInvalidFile}`}
                </div>
            }
            </>;

    }



  return (
    <section className="domain-drop-container">
      <div {...getRootProps({className: 'dropzone'})}>
        <input {...getInputProps()} />
        <p>{CONSTANT_TEXTS.dragFile}  <Button icon="icon-search"  className='dropzone-button' hookId='dropzone-button'>{CONSTANT_TEXTS.Browse}</Button>
                </p>
      </div>
    </section>
  );
}


DomainsDropZone.propTypes ={
    domainsRefresh: PropTypes.number,
    blockBtnText: PropTypes.string,
    getCustomStyles: PropTypes.object,
    formatCreateLabel: PropTypes.func,
    setBlockDomains: PropTypes.func
}
