'use strict';
import {trendDirection, trendType} from "./counterTypes";
import {toMoney, toPercent} from "./formatUtils";
import _ from "lodash";
import {textProvider} from "../components/features/dashboard/imports";
import cx from "classnames";
const {validate} = require('@perion-undertone/ramp-helpers');
const {isNotNumber} = validate;
import {getImagePath} from './../utils/helpers';

export const DIRECTION = {
    UP: 'up',
    DOWN: 'down',
    NONE: 'none'
};

export const TREND = {
    GOOD: 'good-trend',
    BAD: 'bad-trend'
};

export const CONSTANT_TEXTS = {
    TREND_NA: textProvider.getText('trendTooltip', 'trendNA')
};

const CLASS_NAMES = {
    TREND_HOLDER: 'trend-holder',
    TREND: 'trend',
    TREND_MONEY: 'trend-money'
};

export const TREND_CLASS_NAME = 'trend-value';
export const TREND_TYPE = trendType.positive;

export const getTrendTypeClasses = (direction, type) => {
    return {
        [TREND.GOOD]: direction === (type === trendType.positive ? trendDirection.up : trendDirection.down),
        [TREND.BAD]: direction === (type === trendType.positive ? trendDirection.down : trendDirection.up)
    };
};

export const formatTrendValue = (value) => {
    if (_.isNil(value)) {
        return CONSTANT_TEXTS.TREND_NA;
    }
    return toPercent(value, true, false);
};

export const getTrendQuality = (direction, type) => cx(getTrendTypeClasses(direction, type));

export const calculateTrend = (current, previous) => {
    const trend = {
        value: 0,
        direction: DIRECTION.NONE
    };

    const currentVal = parseFloat(current);
    const previousVal = parseFloat(previous);

    if (isNotNumber(currentVal) || isNotNumber(previousVal)) {
        return trend;
    }
    if (previousVal === 0) {
        return trend;
    }
    trend.value = ((currentVal - previousVal) / previousVal) * 100;
    if (!Number.isFinite(trend.value)) {
        trend.value = 0;
        return trend;
    }
    if (trend.value !== 0) {
        trend.direction = trend.value > 0 ? DIRECTION.UP : DIRECTION.DOWN;
        trend.value = Math.abs(trend.value);
    }
    return trend;
};

class TrendCalcForSupplyData {

    constructor() {
        this.placementTypes = [];
        this.visiblePlacementTypes = [];
        this.trendsPerPlacementTypeAndDomain = [];
    }

    /*
        // ex. argument
        extendedPlacementTypes: [
        {
            "name": "Adhesion",
            "adUnitsNames": "Video Adhesion, Expandable Adhesion, Bottom Adhesion",
            "data": [
                {
                    "y": 1.22184,
                    "current": 1.22184,
                    "previous": 878.9262200000001,
                    "trend": {
                        "value": 0.9986098491862035,
                        "direction": "down"
                    }
                },
                {
                    "y": 977.8661399999979,
                    "current": 977.8661399999979,
                    "previous": 1.69219,
                    "trend": {
                        "value": 576.8701800625213,
                        "direction": "up"
                    }
                },
                // ... and some more data ...
             ]
         }  // ... and some more data ...
         ]
     */
    init(extendedPlacementTypes) {
        this.placementTypes = extendedPlacementTypes;
        this.visiblePlacementTypes = [].concat(extendedPlacementTypes);
        return this;
    }

    // we will set null at index for hidden element or e will set object data for visible element
    setPlacementTypeVisibility(name, show) {
        const ind = this.placementTypes.findIndex(pType => pType.name === name);
        if (ind === -1) {return this;}
        if (show) {
            this.visiblePlacementTypes[ind] = this.placementTypes[ind];
        } else {
            this.visiblePlacementTypes[ind] = null;
        }
        return this;
    }

    getTotalTrendFromChart(placementTypeDataIndex) {
        const ptTotalsObj = this.visiblePlacementTypes.reduce((resTotalObj, placementType) => {
            if (_.isEmpty(placementType) || !Array.isArray(placementType.data) || placementTypeDataIndex >= placementType.data.length) {
                return resTotalObj;
            }
            const placementTypeData = placementType.data[placementTypeDataIndex];
            resTotalObj.current += placementTypeData.current;
            resTotalObj.previous += placementTypeData.previous;
            return resTotalObj;
        }, {current: 0, previous: 0});
        return calculateTrend(ptTotalsObj.current, ptTotalsObj.previous);
    }

    getTrendHTML(trend, money) {
        if (this.haveSelectedPlacementTypes()) {
            const {direction, value} = trend;
            const formattedValue = formatTrendValue(value);
            const iconUrl = getImagePath(`${direction}.svg`);
            const showIconStyle = direction === trendDirection.none ? 'display: none' : '';
            const trendQuality = getTrendQuality(direction, TREND_TYPE);
            const moneyHTML = typeof money === 'number' ? (`<span class=" ${CLASS_NAMES.TREND_MONEY}">${toMoney(money)}</span>`) : '';
            return `<div class="${CLASS_NAMES.TREND_HOLDER}">
                    <div class="${CLASS_NAMES.TREND}">
                        ${moneyHTML}
                        <div class="${trendQuality}">
                            <span class="${TREND_CLASS_NAME}">
                                <span style="${showIconStyle}" class="">
                                    <img src="${iconUrl}" />
                                </span>
                                <span>${formattedValue}</span>
                            </span>
                        </div>
                    </div>
                </div>`
        }
        return null;
    }

    getTrendToolTipHTML(graphPoint, fontFamily, revenueTooltipBackground) {
        const money = graphPoint.current;
        const trend = graphPoint.trend;
        const name = graphPoint.series.name;
        const amount = toMoney(money);
        const trendHTML = this.getTrendHTML(trend);
        return `<table style="font-family: ${fontFamily};
                           color: #fff;
                           border-radius: 2px;
                           padding: 5px;
                           background-color: ${revenueTooltipBackground}"
                >
                <tr>
                    <td style="margin-right:5px">${name}:</td>
                    <td>${amount}</td>
                    <td>${trendHTML}</td>
                </tr>
            </table>`
    }

    haveSelectedPlacementTypes() {
        return this.visiblePlacementTypes.some(pType => !_.isEmpty(pType));
    }
}
export const trendCalcForSupplyData = new TrendCalcForSupplyData();