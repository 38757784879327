import {advertisersConstants} from '../constants/advertisersConstants';

export const getAdvertisers = (inputValue) => ({
    type: advertisersConstants.GET_ADVERTISERS_REQUEST,
    payload: {inputValue}
});
export const getAdvertisersSuccess = advertisersList => ({
    type: advertisersConstants.GET_ADVERTISERS_SUCCESS,
    payload: {advertisersList}
});
export const getAdvertisersFailed = error => ({
    type: advertisersConstants.GET_ADVERTISERS_FAILURE,
    payload: {error}
});


export const getBulkAdvertisersUrls = (publisherId, supplySourceIds) => ({
    type: advertisersConstants.GET_ADVERTISERS_URL_REQUEST_BULK,
    payload: {publisherId, supplySourceIds}
});

export const getAdvertisersUrls = (publisherId, supplySourceId, isPublisherLevel) => ({
    type: advertisersConstants.GET_ADVERTISERS_URL_REQUEST,
    payload: {publisherId, supplySourceId, isPublisherLevel}
});
export const getAdvertisersUrlSuccess = advertisersList => ({
    type: advertisersConstants.GET_ADVERTISERS_URL_SUCCESS,
    payload: {advertisersList}
});
export const getAdvertisersUrlFailed = error => ({
    type: advertisersConstants.GET_ADVERTISERS_URL_FAILURE,
    payload: {error}
});

