import React from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import cx from 'classnames';
import PlacementsStatusToggle from '../PlacementsStatusToggle';
import {statusConstants} from '../../../../constants/statusConstants';
import {setPlacementGroupStatus, isAuthorized, permissionConstants} from '../imports';
import get from "lodash/get";

const {EDIT_PLACEMENT_GROUP} = permissionConstants;

const CLASS_NAMES = {
    PLACEMENTS_STATUS_TOGGLE_WRAPPER: 'placements-status-toggle-wrapper',
    PLACEMENTS_STATUS: 'placements-status',
    PLACEMENTS_STATUS_ACTIVE: 'placements-status-active'
};

const StatusFormatter = ({row, value}) =>   {

    const dispatch = useDispatch();

    // Selectors using `useSelector`
    const publisherId = useSelector((state) => state.app.publisher.data.id);
    const placementsPagination = useSelector((state) => state.placements.placementsPagination);
    const searchTerm = useSelector((state) => state.placements.searchTerm);
    const customization = useSelector((state) => state.grid.customization);

    const onPlacementGroupStatusChange = (event, placementGroupId, isChecked) => {
        const {limit, page} = placementsPagination;
        const sort = get(customization, 'sort') || [];
        dispatch(setPlacementGroupStatus(publisherId, placementGroupId, isChecked, limit, page, searchTerm, sort));
    }

    const toggleValue = value === statusConstants.ACTIVE;
    const editPlacement = isAuthorized({permission: EDIT_PLACEMENT_GROUP});
    return (<div className={CLASS_NAMES.PLACEMENTS_STATUS_TOGGLE_WRAPPER}>
        {editPlacement ?
            <PlacementsStatusToggle placementGroupId={row.placementGroupId}
                                    isOn={toggleValue}
                                    onStatusChange={onPlacementGroupStatusChange}
                                    disabled={row.isAuto || false}
            /> :
            <div className={cx(CLASS_NAMES.PLACEMENTS_STATUS, {[CLASS_NAMES.PLACEMENTS_STATUS_ACTIVE]: toggleValue})}>{value}</div>
        }
    </div>);

}

StatusFormatter.propTypes = {
    value: PropTypes.string,
    row: PropTypes.object,
};

export default StatusFormatter;