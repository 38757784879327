import React, {Fragment} from 'react';
import {Route, Redirect, Switch} from 'react-router-dom';
import Home from './Home';

const AppRouter = () => {
    return (
        <Fragment>
                <Switch>
                    <Route
                        path="/internal/:publisherId/*"
                        render={({match}) => (
                            <Redirect to={`/${match.params.publisherId}/${match.params[0]}`} />
                        )}
                    />
                    <Route path="/:publisherId?" component={Home}/>
                </Switch>
        </Fragment>
    )
};

export default AppRouter;
