import {appStates} from '../constants/appStates';
import {advertisersConstants} from '../constants/advertisersConstants';
import {reducerHelper} from './reducerHelper';
import {supplyConstants} from '../constants/supplyConstants';

const initialState = {
    domainsRefresh: 0,
};

export const advertisersReducer = (state = initialState, action) => {
    const {type, payload} = action;
    const advertisersList = 'advertisersList';

    switch (type) {
        case advertisersConstants.GET_ADVERTISERS_REQUEST:
            return reducerHelper(state, advertisersList, undefined, appStates.LOADING);
        case advertisersConstants.GET_ADVERTISERS_SUCCESS:
            return reducerHelper(state, advertisersList, payload.advertisersList, appStates.LOADED);
        case advertisersConstants.GET_ADVERTISERS_FAILURE:
            return reducerHelper(state, advertisersList, undefined, appStates.ERROR);
        case advertisersConstants.GET_ADVERTISERS_URL_REQUEST:
                return reducerHelper(state, 'advertiserUrlList', undefined, appStates.LOADING);
        case advertisersConstants.GET_ADVERTISERS_URL_SUCCESS:
                return reducerHelper(state, 'advertiserUrlList', payload.advertisersList, appStates.LOADED);
        case advertisersConstants.GET_ADVERTISERS_URL_FAILURE:
                return reducerHelper(state, 'advertiserUrlList', [], appStates.ERROR);
        case supplyConstants.BLOCK_ADVERTISERS_DOMAINS_SUCCESS:
            return {
                ...state,
                advertiserUrlList: {
                    data: payload.domains,
                    state: appStates.LOADED
                },
                domainsRefresh: state.domainsRefresh + 1
            };
        default:
            return state;
    }
}