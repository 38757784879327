import {of} from 'rxjs';
import ajax from '../utils/ajaxProxy';
import {mergeMap, map, catchError} from 'rxjs/operators';
import {ofType} from 'redux-observable';
import {advertisersConstants} from '../constants/advertisersConstants';
import {
    getAdvertisersUrlSuccess, getAdvertisersUrlFailed
} from '../actions/advertisersActions';
import {ToastProvider} from '@perion-undertone/ut-react-common';

export const advertisersBulkUrlBlockListEpic = actions => (
    actions.pipe(
        ofType(advertisersConstants.GET_ADVERTISERS_URL_REQUEST_BULK),
        mergeMap(action => {
            const {publisherId, supplySourceIds} = action.payload;
            return ajax.post(`/api/publisher/${publisherId}/supply-sources/domain-blocklist`, {supplySourceIds}, {'Content-Type': 'application/json'}).pipe(
                map(data => {
                    return getAdvertisersUrlSuccess(data.response);
                }),
                catchError(error => {
                    ToastProvider.error('Failed to fetch advertisers urls');
                    return of(getAdvertisersUrlFailed(error));
                }))
            })
        )
    );