import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import cx from 'classnames';
import {Button, Select, Tabs} from '@perion-undertone/ut-react-common';
import {textProvider,
        Icon, SearchBar, appStates, GridNoRowsTemplate} from '../imports';
import BlockListAdvertiserOption from './BlockListAdvertiserOption';
import BlockList from './BlockList';
import BlockedAdvertisersGrid from './BlockedAdvertisersGrid';
import _ from 'lodash';
import isValidDomain from 'is-valid-domain';
import DomainsDropZone from './DomainsDropZone';
const CLASS_NAMES = {
    INNER_CONTAINER: 'supply-settings-modal-inner-container',
    BLOCK_LIST_INNER: 'block-list-inner',
    SECTION_WRAPPER: 'section-wrapper',
    SECTION_WRAPPER_SEPARATOR: 'section-wrapper-separator',
    BLOCK_LIST_TITLE: 'block-list-title',
    BLOCK_LIST_DESCRIPTION: 'block-list-description',
    BLOCK_LIST_SELECT_ADVERTISER: 'block-list-select-advertiser',
    SELECT_LIST_CONTAINER: 'select-list-container',
    SELECT_SALES_ACCOUNTS: 'select-sales-accounts',
    BLOCKED_ADVERTISERS: 'blocked-advertisers',
    BLOCKED_ADVERTISERS_LABEL: 'blocked-advertisers-label',
    BLOCKED_ADVERTISERS_LABEL_NUMBER: 'blocked-advertisers-label-number',
    NO_ADVERTISERS: 'no-advertisers-template',
    FOOTER: 'supply-settings-modal-footer',
    DONE_BUTTON: 'supply-settings-modal-done-button',
};

const CONSTANT_TEXTS = {
    BLOCK_ADVERTISERS: textProvider.getText('supplySourceSettingsModal', 'blockAdvertisersDomains'),
    BLOCK_ADVERTISERS_DESCRIPTION: textProvider.getText('supplySourceSettingsModal', 'blockAdvDescriptionDomains'),
    SELECT_ADVERTISER_DOMAINS: textProvider.getText('supplySourceSettingsModal', 'selectAdvertiserDomains'),
    DOMAINS: textProvider.getText('supplySourceSettingsModal', 'domains'),
    BLOCK: textProvider.getText('supplySourceSettingsModal', 'block'),
    BLOCKED: textProvider.getText('supplySourceSettingsModal', 'blocked'),
    ADVERTISERS: textProvider.getText('supplySourceSettingsModal', 'advertisers'),
    ADVERTISERS_DOMAINS: textProvider.getText('supplySourceSettingsModal', 'advertisersDomains'),
    SEARCH_BY_BLOCKED_DOMAIN: textProvider.getText('adUnits', 'searchByBlockedDomain'),
    NO_ADVERTISERS_DOMAINS: textProvider.getText('supplySourceSettingsModal', 'noAdvertisersDomains'),
    DONE: textProvider.getText('supplySourceSettingsModal', 'doneButton')
}

const getCustomStyles = {
    multiValue: (provided, state) => {
        const value = state.data.value;
        const isInvalidDomain = !isValidDomain(value);
        const color =  isInvalidDomain ? '#ff5b5b !important' : '';
        const borderColor = isInvalidDomain ? '#ff5b5b !important' : '';
        return {...provided, color, borderColor};
    }
};


class BlockListAdvertisersDomains extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchTerm: '',
            domains: [],
            isInvalidDomain: false,
            searchIconClass: 'search_icon.svg'
        }
    }

    componentDidUpdate(prevProps){
        if(prevProps.domainsRefresh !== this.props.domainsRefresh){
            this.setState({domains: []});
        }
    }

    searchAdvertisersGroup = ({target: {value}}) => {
            this.setState({searchTerm: value ,searchIconClass: !value ? 'search_icon.svg' : ''});
    }

    getOptionLabel = (opt) => (opt.name);

    getOptionValue = (opt) => (opt.id);

    formatCreateLabel = (input) => input;

    handleDomainsChange = (domains) => {
        const isInvalidDomain = Array.isArray(domains) ? domains.some(domain => !isValidDomain(domain.value)): false;
        this.setState({domains, isInvalidDomain});
    }

    setBlockDomains = domains => {
        if(_.isNil(domains) && this.state.domains.length > 0){
            const onlyValidDomains = this.state.domains.filter(domain => isValidDomain(domain.value));
            this.props.manageBlockDomains(onlyValidDomains);
        }else if(domains){
            const onlyValidDomains = domains.filter(domain => isValidDomain(domain.value));
            this.props.manageBlockDomains(onlyValidDomains);
        }
    }

    render() {
        const {publisherId, publishersSupplySourcesId,
            onViewChange, advertisers, isPublisherLevel, isBulkBlock, bulkBlockMessage,
            publishersSupplySourcesIds} = this.props;
        const {isInvalidDomain} = this.state;

        
        const blockedAdvertisersUrlData = advertisers.data;
        const numOfBlockedAdvertisers = _.get(blockedAdvertisersUrlData, 'length', 0)
            ? (<div>
                <SearchBar result={this.searchAdvertisersGroup}
                           placeholder={CONSTANT_TEXTS.SEARCH_BY_BLOCKED_DOMAIN}
                           icon={this.state.searchIconClass}
                />
                <div className={CLASS_NAMES.BLOCKED_ADVERTISERS_LABEL}>
                    <span className={CLASS_NAMES.BLOCKED_ADVERTISERS_LABEL_NUMBER}>{`${blockedAdvertisersUrlData.length} ${CONSTANT_TEXTS.BLOCKED}`}</span>
                    {CONSTANT_TEXTS.ADVERTISERS_DOMAINS}
                    {isBulkBlock && bulkBlockMessage}
                </div>
            </div>)
            : null;
        const blockListItem = {component: BlockList, label: 'block-list', viewId: 'block-list'}
        
        return (<Fragment>
            <div className={CLASS_NAMES.INNER_CONTAINER}>
                <div className={cx(CLASS_NAMES.SECTION_WRAPPER, CLASS_NAMES.BLOCK_LIST_INNER)}>
                    <span onClick={onViewChange.bind(this, blockListItem)}>
                        <Icon icon="pagging_arrow_back" hookId="blocklist-advertisers-paging-arrow-back"/>
                    </span>
                    <span className={CLASS_NAMES.BLOCK_LIST_TITLE}>{CONSTANT_TEXTS.BLOCK_ADVERTISERS}</span>
                    <div className={CLASS_NAMES.BLOCK_LIST_DESCRIPTION}>
                        {CONSTANT_TEXTS.BLOCK_ADVERTISERS_DESCRIPTION}
                    </div>
                    <div className={CLASS_NAMES.BLOCK_LIST_SELECT_ADVERTISER}>{CONSTANT_TEXTS.SELECT_ADVERTISER_DOMAINS}</div>
                        <div>{CONSTANT_TEXTS.DOMAINS}</div>
                    <Tabs defaultActiveIndex={0}  className="block-list-domains" hookId="advertisers-search-tabs">
                    <Tabs.Tab header="Free Text" hookId="free-text-advertiser-file">
                    <div className={CLASS_NAMES.SELECT_LIST_CONTAINER}>
                        <div className={CLASS_NAMES.SELECT_SALES_ACCOUNTS}>
                        <Select creatable
                            isMulti                            
                            name="domains"
                            hookId="domains-select"
                            value={this.state.domains}
                            openMenuOnFocus={false}
                            openMenuOnClick={false}
                            styles={getCustomStyles}
                            onChange={this.handleDomainsChange}
                            formatCreateLabel={this.formatCreateLabel}
                            type="domain"
                            components={
                                {Option: BlockListAdvertiserOption}
                            }
                    />

                        {isInvalidDomain && <div className='invalid-domain-error'>*Invalid Domains will not be included</div>}
                        </div>
                        <Button transparent
                                onClick={() => this.setBlockDomains()}
                                className={CLASS_NAMES.CANCEL_BUTTON}
                                disabled={isInvalidDomain || _.get(this.state.domains, 'length',  0) < 1}
                                hookId='handle-block-sales-accounts-btn'
                        >
                            {CONSTANT_TEXTS.BLOCK}
                        </Button>
                    </div>
                    </Tabs.Tab>
                    <Tabs.Tab header="Upload List" hookId="upload-advertiser-file">
                                <DomainsDropZone
                                                domainsRefresh={this.props.domainsRefresh} 
                                                setBlockDomains={this.setBlockDomains} 
                                                blockBtnText={CONSTANT_TEXTS.BLOCK} 
                                                getCustomStyles={getCustomStyles}  
                                                formatCreateLabel={this.formatCreateLabel}
                                            />
                    </Tabs.Tab>
                    </Tabs>   
                </div>
                <div className={CLASS_NAMES.SECTION_WRAPPER_SEPARATOR}></div>
                {advertisers.state === appStates.LOADED ||
                    blockedAdvertisersUrlData && blockedAdvertisersUrlData.length > 0 ?
                        <div className={CLASS_NAMES.BLOCKED_ADVERTISERS}>
                            {numOfBlockedAdvertisers}
                            <BlockedAdvertisersGrid publisherId={publisherId}
                                                    isAdvertiserDomains={true}
                                                    searchTerm={this.state.searchTerm}
                                                    publishersSupplySourcesId={publishersSupplySourcesId}
                                                    blockedAdvertisers={advertisers}
                                                    isPublisherLevel={isPublisherLevel}
                                                    isBulkBlock={isBulkBlock}
                                                    publishersSupplySourcesIds={publishersSupplySourcesIds}
                            />
                        </div> :
                        <GridNoRowsTemplate className={CLASS_NAMES.NO_ADVERTISERS}
                                            message={CONSTANT_TEXTS.NO_ADVERTISERS_DOMAINS}
                        />
                }
            </div>
            <div className={CLASS_NAMES.FOOTER}>
                <Button onClick={this.props.cancelModal}
                        className={CLASS_NAMES.DONE_BUTTON}
                        hookId='done-button'
                >
                    {CONSTANT_TEXTS.DONE}
                </Button>
            </div>
        </Fragment>)
    }
}

BlockListAdvertisersDomains.propTypes = {
    publisherId: PropTypes.number,
    publishersSupplySourcesId: PropTypes.number,
    onViewChange: PropTypes.func,
    searchAdvertisersBlockList: PropTypes.func,
    getAdvertisers: PropTypes.func,
    advertisers: PropTypes.object,
    isPublisherLevel: PropTypes.bool,
    isBulkBlock: PropTypes.bool,
    bulkBlockMessage: PropTypes.object,
    publishersSupplySourcesIds: PropTypes.array,
    onOpenBulkBlockAdvertisersConfirmation: PropTypes.func,
    cancelModal: PropTypes.func,
    manageBlockDomains: PropTypes.func,
    domainsRefresh: PropTypes.number
};

const mapStateToProps = ({advertisers}) => ({
    advertisers: advertisers.advertiserUrlList,
    domainsRefresh: advertisers.domainsRefresh
});

export default connect(mapStateToProps, null)(BlockListAdvertisersDomains);
